import Panel from 'components/Panel';
import Spacer from 'components/Spacer';
import Head from 'next/head';
import Link from 'next/link'
import styled from 'styled-components';
import StyledHeading from 'typography/StyledHeading';
import StyledText from 'typography/StyledText';

const LinkButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 10px 12px;
  margin: 0 auto;
  border: 0;


  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props => props.theme.newColors.BB100)};

  background: ${(props => props.theme.colors.BB100_10_opacity)};

  border-radius: 8px;

  width: 100%;
  @media (min-width: ${(props => props.theme.breakpoints.small)}) {
    width: 210px;
  }

  :hover {
    background: ${(props => props.theme.colors.BB100_15_opacity)};
    transition: background-color 300ms ease-out;
    cursor: pointer;
  }

  :active {
    background: ${(props => props.theme.colors.BB100_20_opacity)};
  }

`;

const FourOhFourPanel = styled(Panel)`
  max-width: 688px;
  margin: 0 auto;
  text-align: center;
  padding: 35px;
  
  > * {
    margin-block-end: 0;
    margin-block-start: 0;
  }
`;

const LogoImage = styled.img`
  display: block;
  width: 100px;
  margin: 34px auto 36px;

  @media (min-width: ${(props => props.theme.breakpoints.small)}) {
    width: 132px;
  }
`;

const SignpostImage = styled.img`
  display: block;
  width: 42px;
  margin: 0 auto 30px;
`;

const HomeImage = styled.img`
  display: block;
  height: 16px;
  margin-right: 12px;
`;

const FourOhFour: React.FC = () => {
  return <>
    <Head>
      <title>Travelnest Direct</title>
    </Head>
    <LogoImage aria-label="travelnest-logo" src="/icons/travelnest-logo-full.svg" />
    <FourOhFourPanel>
      <SignpostImage src="/icons/404-sign.svg" />
      <StyledHeading type="h3" bold>Not all those who wander are lost</StyledHeading>
      <Spacer vertical={8} />
      <StyledText type="body">We couldn’t find this page. The URL could be wrong or no longer exist.</StyledText>
      <Spacer vertical={24} />
      <Link href="/">
        <LinkButton>
          <HomeImage src="/icons/home.svg" />Go to our homepage
        </LinkButton>
      </Link>
    </FourOhFourPanel>
  </>
};

export default FourOhFour;
